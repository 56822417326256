import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import { Modal } from 'react-bootstrap';
import BaseModalHeader from '../../../../../components/baseModal/BaseModalHeader';
import BaseModalFooter from '../../../../../components/baseModal/BaseModalFooter';
import { Controller, useForm } from 'react-hook-form';
import SelectInput from '../../../../travelerForms/components/base/inputs/SelectInput';
import { fetchPlanStatusTypes } from '../../../../../actions/lookupActions';
import { batchUpdatePlanStatus } from '../../../../../actions/plans';
import toastMessageStyles from '../../../../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import WarningModalContent from '../../../../../components/WarningModalContent';

export const enhance = compose(withStyles(styles));

function BatchUpdatePlanStatus(props) {
  const dispatch = useDispatch();
  const planStatusTypes = useSelector(state => state.lookup.allPlanStatusTypes);
  const batchUpdatePlanStatusData = useSelector(state => state.plans.batchUpdatePlanStatus);
  const [planStatusOptions, setPlanStatusOptions] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [showWarningContent, setShowWarningContent] = useState(false);
  const { control, handleSubmit } = useForm({
    values: {
      plan_status_id: '',
    },
  });


  useEffect(() => {
    if (!planStatusTypes.planStatusTypes) {
      dispatch(fetchPlanStatusTypes());
    } else {
      setPlanStatusOptions(
        planStatusTypes.planStatusTypes.map(item => {
          return { label: item.attributes.name, value: item.id };
        }),
      );
    }
  }, [planStatusTypes]);

  const onSubmit = data => {
    const submitValues = {
      plan_ids: props.selectedPlans,
      new_status_id: data.plan_status_id.value,
    };

    setFormValues(submitValues);
    setShowWarningContent(true);

  };

  const onSubmitForm = () => {
    dispatch(
      batchUpdatePlanStatus(formValues, (status, message) => {
        if (status) {
          toast(message, {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });

          props.onClose();
        } else {
          toast(message, {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
        }
      }),
    );
  };

  return (
    <Modal show={props.show} onHide={props.onClose} centered size={showWarningContent ? "md" : "lg"}>
      {showWarningContent ? (
        <WarningModalContent
          text={`You are about to update the plan status of ${props.selectedPlans.length} plan${
            props.selectedPlans.length > 0 ? '(s)' : ''
          }. Notifications of this status change will be sent.`}
          textBold={`Are you sure you sure you want to continue?`}
          onCancel={() => setShowWarningContent(false)}
          onSubmit={onSubmitForm}
          loadingBtn={batchUpdatePlanStatusData?.loading}
        />
      ) : (
        <>
          <BaseModalHeader heading={'Update Plan Status'} onClose={props.onClose} />
          <Modal.Body className={styles.modelBody}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.container}>
                <div className={styles.text}>Select a plan status from the drop down to update the selected plans.</div>

                <div>
                  <Controller
                    control={control}
                    name="plan_status_id"
                    rules={{
                      required: 'Plan status is required',
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <SelectInput
                        label="Plan Status"
                        placeholder={'Select plan status'}
                        required={true}
                        options={planStatusOptions}
                        value={value}
                        handleOnChange={onChange}
                        isMulti={false}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
              </div>

              <BaseModalFooter
                mainBtnType="submit"
                mainBtnText="Update"
                isBtnGroupRight={true}
                onCancel={props.onClose}
              />
            </form>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}

BatchUpdatePlanStatus.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchUpdatePlanStatus);
